export default {
  en: {
    privacy: {
      title : 'Privacy Policy',
    },
  },
  th: {
    privacy: {
      title : 'นโยบายความเป็นส่วนตัว',
    },
  },
};
