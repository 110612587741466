























































































































































































































































// require styles
import {Component, Vue} from 'vue-property-decorator';
import NavBar from '../components/NavBar.vue';
import Footer from '../components/Footer.vue';
import Modal from '../components/Modal.vue';
import seeMoreDetail from '../mixins/seeMoreDetail';
import consultWithPsychologist from '../mixins/consultWithPsychologist';
import 'aos/dist/aos.css'

export default Vue.extend({
    mixins: [seeMoreDetail, consultWithPsychologist],
  data(): {
    showVideoModal: boolean,
    query: object,
    webAppUrl: string,

  } {
    return {
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
      showVideoModal: false,
      query: {organization: 'chevron'},
    };
  },
  computed: {
    goToApp(): string {
      const str = `${this.webAppUrl}/user/register?`;
      const query: any = this.query;
      return str + Object.keys(query).map((key) => {
        return key + '=' + query[key];
      }).join('&');
    },
  },
  methods: {
    checkBrowser() {
      if (this.detectIE()) {
        // alert('eww IE');
        // console.log('eww IE');
        alert('ท่านสามารถลงทะเบียนใช้งาน “EAP Worklife Services ” ในช่องทาง www.ooca.co ผ่าน browser Google Chorme หรือ Firefox');
      } else {
        // alert('yeah');
        // console.log('yeah');
      }
    },
    detectIE() {
      const ua = window.navigator.userAgent;

      const msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      const trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      const edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }

      // other browser
      return false;
    },
  },
  mounted() {
    if (this.$route.query) {
      const query = this.$route.query;
      // this.query = this.$route.query;
      if (this.$route.query) {
        if (query.email) {
          this.query = Object.assign({}, this.query, {email: query.email});
        }
        if (query.email_verification_token) {
          this.query = Object.assign({}, this.query, {email_verification_token: query.email_verification_token});
        }
      }
    }
    import('aos').then(AOS => AOS.init())
  },
  components: {
    NavBar,
    Footer,
    Modal,
  },
  // metaInfo: {
  //   links: [
  //     {rel: 'canonical', href: 'https://ooca.co'},
  //   ],
  // },
});
