



















































































































































































































































































































































import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import careerAvailable from '../data/careerAvailable';
import 'aos/dist/aos.css'

class OurTeam {
  public logo: string;
  public name: string;
  constructor(logo: string, name: string) {
    this.logo = logo;
    this.name = name;
  }
}

class Career {
  public logo: string;
  public position: string;
  constructor(logo: string, position: string) {
    this.logo = logo;
    this.position = position;
  }
}


export default Vue.extend({
  data(): {
    ourTeams: OurTeam[],
    careers: Career[],
    ourTeamIndex: number,
    careerIndex: number,
    OurTeamIterationInterval: number,
    webAppUrl: string,
  } {
    return {
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
      ourTeams: [
        new OurTeam('eix', '1'),
        new OurTeam('jib', '2'),
        new OurTeam('please', '3'),
        new OurTeam('fern', '4'),
        // new OurTeam('ji', '5'),
        // new OurTeam('provider3', '4'),
        // new OurTeam('provider4', '5'),
      ],
      careers: [
        // new Career('', 'headOfTechnology'),
        new Career('', 'accountExecutive'),
        new Career('', 'businessDevelopment'),
        new Career('', 'csrProjectManager'),
        new Career('', 'marketingCommunication'),
        new Career('', 'assistant'),
        new Career('', 'prm'),
        new Career('', 'psychologist'),
        // new Career('', 'mobiledev'),
        new Career('', 'ux'),
        new Career('', 'graphicDesigner'),
        // new Career('ux', 'ux'),
        // new Career('frontend', 'frontend'),
      ],
      ourTeamIndex: 0,
      careerIndex: 0,
      OurTeamIterationInterval: 0,
    };
  },
  computed: {
    currentOurTeam(): OurTeam {
      return this.ourTeams[this.ourTeamIndex];
    },
    OurTeamSwiper(): any {
      return (this.$refs.OurTeamSwiper as any).swiper;
    },
  },
  methods: {
    getOurTeamLogoUrl(logoName: string) {
      return require(`../assets/our-team/${logoName}.jpg`);
    },
    shiftOurTeam() {
      this.ourTeamIndex = (this.ourTeamIndex + 1) % this.ourTeams.length;
      this.OurTeamSwiper.slideNext();
    },
    slideOurTeamTo(index: number) {
      this.OurTeamSwiper.slideToLoop(index);
    },
    getCurrentCareerPosition() {
      return this.careers[this.careerIndex].position;
    },
    getCurrentOurTeamPosition() {
      return this.$t(`career.waiting.position.${this.currentOurTeam.name}`);
    },
    getCurrentOurTeamName() {
      return this.$t(`career.waiting.name.${this.currentOurTeam.name}`);
    },
    getCareerName(index: number) {
      // return this.$t(`career.opening.career.${this.careers[index].position}.name`);
      return careerAvailable[`${this.careers[index].position}`].name;
    },
    getCurrentCareerName() {
      // return this.$t(`career.opening.career.${this.careers[this.careerIndex].position}.name`);
      return careerAvailable[`${this.careers[this.careerIndex].position}`].subName;
    },
    getCurrentCareerLogo(index: number) {
      return require(`../assets/careers/${this.careers[this.careerIndex].logo}.svg`);
    },
    getCurrentCareerResponsibility(index: number) {
      // return this.$t(`career.opening.career.${this.careers[this.careerIndex].position}.responsibility`);
      return careerAvailable[`${this.careers[this.careerIndex].position}`].responsibility;
    },
    getCurrentCareeryouHaveShouldBe(index: number) {
      // return this.$t(`career.opening.career.${this.careers[this.careerIndex].position}.requirement`);
      return careerAvailable[`${this.careers[this.careerIndex].position}`].youHaveShouldBe;
    },
    getCurrentCareerInterestedSkills(index: number) {
      // return this.$t(`career.opening.career.${this.careers[this.careerIndex].position}.requirement`);
      return careerAvailable[`${this.careers[this.careerIndex].position}`].interestedSkills;
    },
    getCurrentCareerBenefits(index: number) {
      // return this.$t(`career.opening.career.${this.careers[this.careerIndex].position}.benefit`);
      return careerAvailable[`${this.careers[this.careerIndex].position}`].benefits;
    },
    getCurrentCareerWhatYouGet(index: number) {
      // return this.$t(`career.opening.career.${this.careers[this.careerIndex].position}.whatYouGet`);
      return careerAvailable[`${this.careers[this.careerIndex].position}`].whatYouGet;
    },
    getCurrentCareerDescription(index: number) {
      // return this.$t(`career.opening.career.${this.careers[this.careerIndex].position}.whatYouGet`);
      return careerAvailable[`${this.careers[this.careerIndex].position}`].description;
    },
    selectJob(index: number) {
      this.careerIndex =  index;
      this.$router.replace({
        params: Object.assign({}, this.$route.params, { position:  this.careers[this.careerIndex].position }),
      });
    },
  },
  mounted() {
    import('aos').then(AOS => AOS.init());
    this.OurTeamIterationInterval = window.setInterval(this.shiftOurTeam, 10000);
    this.OurTeamSwiper.on('slideChangeTransitionEnd', () => {
      this.ourTeamIndex = this.OurTeamSwiper.realIndex;
    });
    const careerName =  this.$route.params.position;
    const pos =  this.careers.map(function(e) { return e.position; }).indexOf(careerName);
    this.$router.replace({
      params: Object.assign({}, this.$route.params, { position:  this.careers[pos].position }),
    });
    // console.log(pos);
    this.careerIndex =  pos;
  },
  beforeDestroy() {
    window.clearInterval(this.OurTeamIterationInterval);
  },
  components: {
    NavBar,
    Footer,
    swiper,
    swiperSlide,
  },
});
