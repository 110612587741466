export default {
  en: {
    joinCareer: {
      title: 'join ooca team',
      subtitle: 'You’re welcome at Ooca! We are the leading <br>' +
      'Tele-mental Health platform in Thailand.',
      seeAll : 'See all opening Position',
      form: {
        title: 'Personal Information',
        fullName: {
          label: 'Your full name',
          placeholder: 'Jane Doe',
        },
        email: {
          label: 'Your email',
          placeholder: 'jane@corporate.com',
        },
        phoneNumber: {
          label: 'Your phone number',
          placeholder: '081-123-4567',
        },
        website: {
          label: 'Website/Online Portfolio',
          placeholder: 'Url',
        },
        notes : {
          label : 'Notes',
          placeholder :  'Add cover letter or anything to us',
        },
        upload : {
          label : 'Please attach your resume and supporting docs*',
          resume: 'Upload Resume',
          more: '+ Upload more',
          delete: 'Delete file',
        },
        btn: 'submit application',
      },
      success : {
        title : 'Your application successfully admited!',
        text : 'The confirmation for your application already sent to your email,<br>' +
        'we’ll get in touch as soon as possible<br>' +
        'or reach us at',
        text2 : 'for further inquiries',
        goBack : 'Go back',
      },
      error : {
        422 : 'Please complete all the required fields.',
      },
    },
  },
  th: {
    joinCareer: {
      title: 'มาร่วมเป็นส่วนหนึ่งของครอบครัวอูก้า',
      subtitle: 'ร่วมเป็นส่วนหนึ่งของอูก้า! เราคือแพลตฟอร์มให้คำปรึกษา<br>ออนไลน์แนวหน้าของไทย',
      seeAll : 'ตำแหน่งอื่นๆที่เปิดรับอยู่',
      form: {
        title: 'ข้อมูลส่วนตัว',
        fullName: {
          label: 'ชื่อของคุณ',
          placeholder: 'เจน โด',
        },
        email: {
          label: 'อีเมล์ของคุณ',
          placeholder: 'jane@corporate.com',
        },
        phoneNumber: {
          label: 'เบอร์ติดต่อของคุณ',
          placeholder: '081-123-4567',
        },
        website: {
          label: 'เว็บไซต์/ผลงานออนไลน์',
          placeholder: 'Url',
        },
        notes : {
          label : 'ข้อความเพิ่มเติม',
          placeholder :  'แนะนำตัวเพิ่มเติมหรือข้อความที่อยากฝากถึงเรา',
        },
        upload : {
          label : 'กรุณาแนบประวัติส่วนตัว ผลงานหรือเอกสารเพิ่มเติม*',
          resume: 'อัปโหลดประวัติส่วนตัว',
          more: '+ อัปโหลดเพิ่ม',
          delete: 'ลบไฟล์',
        },
        btn: 'ส่งใบสมัคร',
      },
      success : {
        title : 'ระบบได้รับใบสมัครของคุณเรียบร้อยแล้ว!',
        text : 'อูก้าได้ส่งอีเมล์ยืนยันไปที่อีเมล์ของคุณของคุณแล้ว,<br>' +
        'เราจะติดต่อกลับไปโดยเร็วที่สุด<br>' +
        'หรือติดต่อเราได้ที่',
        text2 : 'หากต้องการข้อมูลเพิ่มเติม',
        goBack : 'กลับไปหน้าเดิม',
      },
      error : {
        422 : 'โปรดกรอกข้อมูลให้ครบถ้วน',
      },
    },
  },
};
