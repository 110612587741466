// config here and config in route
const career: any = {
  headOfTechnology: {
    name: 'Head of Technology<br> (3-7+ years)',
    subName:
      'Head of Technology (3-7+ years)',
    // tslint:disable-next-line:max-line-length
    youHaveShouldBe: [
      'Required skills / experiences3 - 5 + years mobile application / web application developments3 + years experience in node.js, React Native, php, sql, no - sq',
    ],
    responsibility: [
      'Design and develop web/mobile application (frontend architecture, backend architecture, focus on performance reliability security maintainable system)',
      'Manage team of 4-6 developers',
      'Take the initiative in thought leadership, innovation and creativity of technology team. Build proper culture and working ethic within the team',
      'Design and develop frontend architecture, backend architecture, focus on performance reliability security maintainable system.',
      'Represent the technological agenda in staff meetings and when making hiring decisions',
      'Maintain current knowledge of technology landscape and developments',
      'Consolidate our technology platforms and create plans for each',
      'Track, analyze and monitor technology performance metrics',
      'Identify new areas of digital marketing opportunities and create plans to implement them for us and our clients',
    ],
    interestedSkills: [
      'Project management and organization skills',
      'Typescript.',
      'Jest.',
      'React, Vue. React-Native, Cordova.',
      'Redux, MobX.',
      'GraphQL (apollo, relay).',
      'Functional Programming, RxJS.',
      'Java, Obj C, Swift, Kotlin.',
      'Node.js, PHP, dotNet core or Go.',
      'Load balance, Queue, Caching.',
      'DevOps (AWS, GCP).',
      'Voip (voice, video, telephony).+ IVR',
      'Machine Learning/ Artificial Intelligence',
      'Big Data',
    ],
    benefits: [
      'Social Security Insurance + Health Insurance',
      'Free lunch when working at the office',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  accountExecutive: {
    name: 'Account Executive',
    subName: 'Account Executive',
    // tslint:disable-next-line:max-line-length
    youHaveShouldBe: [
      'At least 2 - year experience as sales representative or related field',
      'Good attitude in workplace and maintaining relationship with others',
      'Open minded and strategic thinking ability',
      'Good command of English',
      'B2B experience will have high consideration',
    ],
    responsibility: [
      'Identify and develop sales opportunities',
      'Initiate business plans and pitching projects',
      'Efficiently manage and prioritize various sales-related activities',
      'Maintain favorable customer relations by demonstrating problem-solving skills',
      'Contribute directly to the success and growth of the company',
    ],
    benefits: [
      'Social Security Insurance + Health Insurance',
      'Transportation fee when traveling',
      'Phone call fee with sim cards and a phone',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  assistant: {
    name: 'Team Assistant',
    subName: 'Team Assistant',
    youHaveShouldBe: [
      'New graduates are welcome',
      'Good attitude in workplace and maintain relationship with others',
      'Open minded and strategic thinking ability',
      'Good command of English',
      'Be responsible, creative, high E.Q. and assertive',
      'Have a pleasant personality, leadership skill, and communication skill',
      'Ability to work under pressure',
    ],
    responsibility: [
      'Internal and external coordination',
      'Conduct projects and documentation as assigned',
      'Report directly to the CEO',
      'Manage team schedules and appointments',
      'Conduct out-of-office activities and support the preparation process.',
      'Manage a reimburse the expenses system and keep records',
      'Support team according to the task assigned',
      'Have an Interest and an ability to utilize digital technology in the workplace',
      'Have an interest in technology and keen to try innovative tech before others',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Transportation fee when traveling',
      'Phone call fee with sim cards and a phone',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Working in Tech environment',
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Cooperate with government sectors to raise awareness in mental health issue for Thai people in each segment',
      'We are currently developing projects in both academic and international sectors.',
    ],
  },
  mobiledev: {
    name: 'Mobile developer <br>(Native iOS, Android)',
    subName: 'Mobile developer <br>(Native iOS, Android)',
    youHaveShouldBe: [
      'Proficient with Swift and Cocoa Touch',
      'Experience with iOS frameworks such as Core Data, Core Animation, etc.',
      'Experience with Android framework.',
      'Experience with Kotlin is a preferred.',
      'Familiarity with RESTful APIs to connect iOS and Android applications to back-end services',
      'Experience with working with back-end framework such as Laravel, dJango or Flask',
      'Knowledge of other web technologies and UI/UX standards',
      'Understanding of design principles and interface guidelines',
      'Familiarity with cloud message APIs and push notifications',
      'Proficient understanding of code versioning using Git',
    ],
    responsibility: [
      // tslint:disable-next-line:max-line-length
      'Translate concepts into user flows, wireframes, mockups and prototypes that lead to intuitive user experiences.',
      // tslint:disable-next-line:max-line-length
      'Facilitate the client’s product vision by researching, conceiving, sketching, prototyping and user-testing experiences for digital products.',
      // tslint:disable-next-line:max-line-length
      'Design and deliver wireframes, user stories, user journeys, and mockups optimized for a wide range of devices and interfaces.',
      'Identify design problems and devise elegant solutions.',
      // tslint:disable-next-line:max-line-length
      'Make strategic design and user-experience decisions related to core, and new, functions and features.',
      'Take a user-centered design approach and rapidly test and iterate your designs.',
      'Collaborate with other team members and stakeholders.',
      'Ask smart questions, take risks and champion new ideas.',
    ],
    benefits: [
      'Social Security Insurance + Health Insurance',
      'Transportation fee when traveling',
      'Free lunch',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  ux: {
    name: 'UX/UI Designer',
    subName: 'UX/UI Designer',
    youHaveShouldBe: [
      'Previous experience working with developers team and UX UI responsive design understanding',
      'Fast learner and mover',
      'Flexible and hardworking',
      'Sketch or Abode XD User. We do not look for UX/UI that rely only on Photoshop and Illustrator',
      'At least 2 years of experience',
    ],
    responsibility: [
      // tslint:disable-next-line:max-line-length
      'Design both UX and UI for both mobile and website. We understand the differences of UX and UI but as a start-up company we need to move fast. To be both a great visual artist with user experience understanding will benefit our time meticulously.',
      // tslint:disable-next-line:max-line-length
      'User research and analysis for UX improvement',
      // tslint:disable-next-line:max-line-length
      'Loves talking to people, conducting user interview, gaining insights and able to set up objectives of the interview.',
      'Collaborate with developers, stakeholders and CEO to create great design',
    ],
    benefits: [
      'Social Security Insurance + Health Insurance',
      'Transportation fee when traveling',
      'Free lunch',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  prm: {
    name: 'Provider Relationship Manager',
    subName: 'Provider Relationship Manager',
    youHaveShouldBe: [
      'Proven experience in health-care field especially in mental health',
      'Good attitude in workplace and maintaining relationship with others',
      'Strong interpersonal and communication skills with good service mind',
      'Experience as drug detailing will have high consideration',
      'Team management abilities',
      'Tech savvy',
    ],
    responsibility: [
      // tslint:disable-next-line:max-line-length
      'Acquire/Recruit/Interview new psychiatrist/psychologist to join Ooca online counseling platform',
      // tslint:disable-next-line:max-line-length
      'Maintain good relationship and communicate with providers on Ooca platform',
      // tslint:disable-next-line:max-line-length
      'Onboard psychiatrist/psychologist on Ooca platform',
      'Community building within groups on mental health providers on Ooca',
      'Set up provider shifts and arrange to have shifts covered',
      'Oversee the daily operations of providers in Ooca',
      'Delegate tasks to providers and supervising their performance',
      'Ensure satisfaction and gracefully handle any complaints',
      'Help providers resolve on-the-job challenges',
      'Report issues and training needs',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Phone call fee with sim cards and a phone',
      'Commission',
      'Free lunch',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  businessDevelopment: {
    name: 'Business Development/Business Analyst',
    subName: 'Business Development/Business Analyst',
    youHaveShouldBe: [
      'At least 1 year experience as a Business development/ Business analyst or related role',
      'Strategic thinking, result oriented and link initiatives/actions to company objectives',
      'Having commercial and economic awareness',
      'Excellent in communication and interpersonal skills',
      'Good attitude in workplace and maintaining relationship with others',
      'Good command of English',
    ],
    responsibility: [
      'Study business requirements, analyze data and translate results',
      'Conduct root cause analysis or correlation studies when required',
      'Consolidate standardized data for reporting purposes',
      'Monitor, alert, and communicate appropriate trends based on business goals',
      'Establish project management skills with project scheduling and tracking documents',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  psychologist: {
    name: 'Full-time Psychologist',
    subName: 'Full-time Psychologist',
    youHaveShouldBe: [
      'วุฒิ ปริญญาโทหรือเอก สาขาวิชาจิตวิทยาการให้คำปรึกษา หรือสาขาอื่นๆ ที่เกี่ยวข้องกับการให้คำปรึกษาทางด้านจิตวิทยา',
      'มีประสบการณ์ทำงานในฐานะผู้ให้คำปรึกษาทางด้านจิตวิทยามาแล้วมากกว่า 2 ปี',
    ],
    responsibility: [
      'งานให้คำปรึกษาก็มีให้ทำเก็บเกี่ยวประสบการณ์ตลอด แต่ส่วนมากเราเน้นคนไข้เบาๆไม่ใช่เคสหนัก เพราะเราสนใจทางด้าน wellness มากกว่า',
      'มีความรับผิดชอบ มีความคิดสร้างสรรค์ และมี E.Q.ดี กล้าแสดงออก สามารถสอนสัมมนา หรือ พูดประชาสัมพันธ์โครงการการให้คำปรึกษาได้ มีบุคลิกภาพ ภาวะความเป็นผู้นำ มีมนุษยสัมพันธ์ และทักษะการติดต่อสื่อสารที่ดี',
      'งานนี้เจอคนเยอะ ต้องได้เจอกับคนมากมาย ไม่ว่าจะออนไลน์หรือ ออฟไลน์',
      'สามารถเดินทางไปต่างจังหวัดเพื่อจัดกิจกรรมได้',
      'มีงานบ้างส่วนต้องไปจัดกิจกรรมนอกสถานที่ด้วย สายเที่ยวชอบลุยต้องมา!',
      'สนใจและมีความสามารถในการใช้เทคโนโลยีในปัจจุบันในการทำงาน',
      'ชอบเทคโนโลยี อยากทำอะไรใหม่ๆที่สร้างสรรค์ ที่นี่มีให้คุณได้ลองก่อนเพื่อน',
    ],
    benefits: [
      'ประกันสังคม ประกันสุขภาพกลุ่ม',
      'ค่าเดินทางเกี่ยวข้องกับเดินทางไปข้างนอก',
      'ฟรีอาหารกลางวัน',
    ],
    whatYouGet: [
      'ทำงานกับเทคโนโลยีตลอด',
      'ลูกค้าของเราเป็นบริษัทชั้นนำของประเทศไทย',
      'เราร่วมมือพัฒนาสังคมกับมูลนิธิ และสถาบันการศึกษา เพื่อสร้างเครื่องมือที่จะช่วยเหลือคนไทยได้อย่างยั่งยืน',
      'ทางภาครัฐเราก็ไม่ละทิ้ง กำลังจะมีโปรเจคร่วมกับภาครัฐเพื่อผลักดันเรื่องสุขภาพจิตสำหรับคนไทยในหลายๆมุม',
      'เรากำลังพัฒนาโปรเจคหลายๆด้านทั้ง ภาคการศึกษา และต่างประเทศ',
    ],
  },
  csrProjectManager: {
    name: 'CSR Project Manager',
    subName: 'CSR Project Manager',
    description: '&nbsp;&nbsp;&nbsp;Ooca is setting up a CSR project that focuses on improving access to mental health nationwide. In 2019, we are focusing on improving access and provide free counseling service to university students in order to support their mental wellness and prevent suicide. We are looking for a well-rounded managerial and collaborative person. The CSR project manager will work directly with C-level executives and will be responsible for managing and running a CSR project, connecting and working with education institutes, construct and run promotion plan, connect and fundraised through corporates grants and crowdfunding. The ideal candidate is a senior well-managed, highly self-motivated, professional, and capable of managing workload and prioritizing tasks in a fast-paced corporate environment. This is an excellent opportunity to join a growing company with competitive benefits.',
    youHaveShouldBe: [
      'Previous managerial background with operational experiences',
      'Understand and share vision toward social oriented projects',
      'Quick learner, open-minded, tech-savvy, and like to learn new things.',
      'Basic use of Microsoft office (Word, Powerpoint, Excel and etc.).',
      'Familiar with social enterprise or NGO and understand working morale within this area',
      'Fast and attention to details',
      'Ability to work under pressure',
      'Positive attitude with team spirit',
      'Liking to work in startup environment',
    ],
    responsibility: [
      'Act as a key representative to the CSR project, working, connecting and maintaining relationships with beneficiaries and  patroned institutes',
      'Manage and runs the CSR project',
      'Draft proposals and PR pieces',
      'Fundraised through corporates and crowdfunding platform',
      'Plan operational task and promotion plan',
      'Work as goal-oriented and directly reports to the CEO.',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Free lunch when working at the office',
      'Other allowances e.g. working on weekends, off-site working and etc.',
    ],
    whatYouGet: [
      'Opportunity to learn and  thrived from various challenging projects.',
      'Salary with annual bonus based on individual and company performance',
      'Friendly environment with the modern FYI office located in the center of Bangkok (Next to the MRT Queen Sirikit National Convention Centre; Exit 1)',
    ],
  },
  graphicDesigner: {
    name: 'Graphic Designer',
    subName: 'Graphic Designer',
    youHaveShouldBe: [
      'Design visual requirement for Ooca product such as; illustration, print-materials and etc.',
      'Collaborate with developers, stakeholders and UX/UI designer to create great design',
    ],
    responsibility: [
      'Great sense of esthetic design and urge to understand before design',
      'Fast learner and mover',
      'Flexible and hardworking',
      'Sketch, Adobe Photoshop, Adobe illustrator or etc.',
      'Commitment with deadlines',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Transportation fee when traveling',
      'Phone call fee with sim cards and a phone',
      'Commission',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
  marketingCommunication: {
    name: 'Marketing Communication',
    subName: 'Marketing Communication',
    youHaveShouldBe: [
      'At least 2 - year experience as Marketing Communication, Content, PR, Branding or relevant field',
      'Excellent communication skills both orally and in writing',
      'Excellent interpersonal skills',
      'Good Presentation skills',
      'Familiarity with B2B and B2C marketing activities',
      'Knowledge of SEO and Google Analytics',
      'Creativity and an ability to produce innovative and original ideas',
      'Expertise in social media platforms',
    ],
    responsibility: [
      'Set up plan promote our company, products and services through various channels',
      'Coordinate the design of materials and distribute in online and offline channels',
      'Produce marketing copy for our website and other channels',
      'Network with industry experts and potential clients to drive brand awareness',
      'Gather customer feedback to inform sales and product teams including analysing the success of marketing activities and creating report',
      'Design content marketing strategies and set short-term goals',
      'Undertake content marketing initiatives to achieve business targets',
      'Collaborate with design other related teams to produce high quality content',
      'Deliver engaging content on a regular basis and inspire team members',
      'Edit, proofread and improve content',
      'Optimize content considering SEO and Google Analytics and analyze web traffic metrics',
      'Share content through various channels, ensuring strong web presence',
      'Receive customer feedback and generate ideas to increase customer engagement',
      'Carrying out market research in order to keep up to date with customer trends, as well as trying to predict future trends',
      'Planning publicity strategies and activities',
      'Writing and producing presentations and other releases',
    ],
    benefits: [
      'Social Security Insurance and Health Insurance',
      'Transportation fee when traveling',
      'Free lunch when working at the office',
    ],
    whatYouGet: [
      'Challenging work experience with opportunities to work with top-tier corporate clients',
      'Friendly working atmosphere with easy-to-commute location we are working at FYI center at the moment',
      'Classes and training program at Thailand\'s top accelerator program',
    ],
  },
};

export default career;
