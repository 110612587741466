export default {
  en: {
    navBar: {
      home : 'Home',
      services: 'Services',
      serviceSub: {
        counseling: 'Counseling',
        test: 'Inventory test',
        voucher: 'Gift voucher',
        corporate: 'Corporate',
      },
      pricing: 'Pricing',
      providers: 'Providers',
      about: 'About',
      support: 'Support',
      career: 'Career',
      signin: 'Sign in',
      getStarted: 'Get Started',
    },
  },
  th: {
    navBar: {
      home: 'หน้าหลัก',
      services: 'บริการ',
      serviceSub: {
        counseling: 'บริการให้คำปรึกษา',
        test: 'แบบทดสอบความเครียด',
        voucher: 'บัตรของขวัญ',
        corporate: 'สำหรับองค์กร',
        blog:'บล็อก'
      },
      pricing: 'ราคา',
      providers: 'ผู้ให้คำปรึกษา',
      about: 'เกี่ยวกับเรา',
      support: 'การใช้งาน',
      career: 'ร่วมงานกับเรา',
      signin: 'เข้าสู่ระบบ',
      getStarted: 'เริ่มต้นใช้งาน',
    },
  },
};
