











































































































































































































































































































































































































































































































































































































































// require styles
import 'swiper/dist/css/swiper.css';
const moment = require('moment-timezone');
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { Component, Vue } from 'vue-property-decorator';
import NavBar from '../components/NavBar.vue';
import Footer from '../components/Footer.vue';
import Modal from '../components/Modal.vue';
import 'aos/dist/aos.css'


class Testimonial {
  public logo: string;
  public quote: string;
  constructor(logo: string, quote: string) {
    this.logo = logo;
    this.quote = quote;
  }
}

export default Vue.extend({
  data(): {
    showAlert: boolean,
    testimonials: Testimonial[],
    testimonialIndex: number,
    testimonialIterationInterval: number,
    showVideoModal: boolean,
    webAppUrl: string,
  } {
    return {
      showAlert: false,
      testimonials: [
        new Testimonial('ptt', ''),
        new Testimonial('rabbit', 'rabbit'),
        new Testimonial('scg', 'scg'),
        new Testimonial('dga', 'dga'),
        new Testimonial('dtac', 'dtac'),
        new Testimonial('roche', 'roche'),
        new Testimonial('dva', ''),
        new Testimonial('aycap', ''),
      ],
      testimonialIndex: 0,
      testimonialIterationInterval: 0,
      showVideoModal: false,
      webAppUrl: process.env.VUE_APP_WEB_APP_LOCATION,
    };
  },
  computed: {
    currentTestimonial(): any {
      return this.testimonials[this.testimonialIndex];
    },
    testimonialSwiper(): any {
      return (this.$refs.testimonialSwiper as any).swiper;
    },
  },
  methods: {
    getTestimonialLogoUrl(logoName: string) {
      return require(`../assets/testimonials/testimonial-${logoName}.png`);
    },
    shiftTestimonial() {
      this.testimonialIndex = (this.testimonialIndex + 1) % this.testimonials.length;
      this.testimonialSwiper.slideNext();
    },
    slideTestimonialTo(index: number) {
      this.testimonialSwiper.slideToLoop(index);
    },
    getCurrentTestimonialAvatar() {
      // return require(`../assets/testimonials/avatar-${this.currentTestimonial.quote}.png`);
      return require(`../assets/testimonials/avatar-ptt.png`);
    },
    // getCurrentTestimonialQuote() {
    //   return this.$t(`home.testimonials.quotes.${this.currentTestimonial.quote}`);
    // },
    // getCurrentTestimonialCredit() {
    //   return this.$t(`home.testimonials.credits.${this.currentTestimonial.quote}`);
    // },
  },
  mounted() {
  import('aos').then(AOS => AOS.init());
  this.showAlert = false;
  if(moment().isBefore('2019-01-02')) {
    this.showAlert = true;
  }
  this.testimonialIterationInterval = window.setInterval(this.shiftTestimonial, 10000);
  this.testimonialSwiper.on('slideChangeTransitionEnd', () => {
    this.testimonialIndex = this.testimonialSwiper.realIndex;
    });
  },
  beforeDestroy() {
    window.clearInterval(this.testimonialIterationInterval);
  },
  components: {
    NavBar,
    Footer,
    swiper,
    swiperSlide,
    Modal,
  },
  // metaInfo: {
  //   links: [
  //     {rel: 'canonical', href: 'https://ooca.co'},
  //   ],
  // },
});
