export default {
  en: {
    joinProvider: {
      title: 'join as ooca provider',
      subtitle: 'You’re welcome at Ooca! We are the leading <br>' +
      'Tele-mental Health platform in Thailand.',
      form: {
        title: 'Personal Information',
        fullName: {
          label: 'Your full name (Thai)',
          placeholder: 'เจน โด',
        },
        email: {
          label: 'Your email',
          placeholder: 'jane@corporate.com',
        },
        phoneNumber: {
          label: 'Your phone number',
          placeholder: '081-123-4567',
        },
        providerCategory: {
          label: 'Provider category',
          choices : {
            psychiatrist : 'Psychiatrist',
            psychologist : 'Psychologist',
          },
        },
        license: {
          label: 'Where did you got the practice license?',
          choices : {
            thailand : 'Thailand',
            other : 'Other Country',
          },
        },
        licenseNumber: {
          label: 'License Number',
        },
        referee: {
          label: 'Professional referee',
          placeholder1: 'Referee’s full name',
          placeholder2: 'Referee’s phone number',

        },
        platform : {
          label : 'Preferable working platform',
          choices : {
            1 : 'Application',
            2 : 'Hotline',
            3 : 'Application and Hotline',
          },
        },
        upload : {
          label : 'Please attach your resume and certificates*',
          resume: 'Upload Resume',
          cert: 'Upload Certificate',
          more: '+ Upload more',
          delete: 'Delete file',
        },
        btn: 'submit application',

      },
    },
  },
  th: {
    joinProvider: {
      title: 'สมัครเป็นผู้ให้คำปรึกษาของอูก้า',
      subtitle: 'ร่วมเป็นส่วนหนึ่งของอูก้า! เราคือแพลตฟอร์มให้คำปรึกษา<br>ออนไลน์แนวหน้าของไทย',
      form: {
        title: 'ข้อมูลส่วนตัว',
        fullName: {
          label: 'ชื่อของคุณ (ภาษาไทย)',
          placeholder: 'เจน โด',
        },
        email: {
          label: 'อีเมล์ของคุณ',
          placeholder: 'jane@corporate.com',
        },
        phoneNumber: {
          label: 'เบอร์ติดต่อของคุณ',
          placeholder: '081-123-4567',
        },
        providerCategory: {
          label: 'ประเภทผู้ให้บริการ',
          choices : {
            psychiatrist : 'จิตแพทย์',
            psychologist : 'นักจิตวิทยา',
          },
        },
        license: {
          label: 'ใบรับรองการทำงานของคุณออกในประเทศใด',
          choices : {
            thailand : 'ประเทศไทย',
            other : 'ประเทศอื่น',
          },
        },
        licenseNumber: {
          label: 'เลขใบรับรองการทำงาน',
        },
        referee: {
          label: 'บุคคลอ้างอิงการทำงาน',
          placeholder1: 'ชื่อของบุคคลอ้างอิง',
          placeholder2: 'เบอร์ติดต่อของบุคคลอ้างอิง',

        },
        platform : {
          label : 'แพลตฟอร์มที่ต้องการสมัคร',
          choices : {
            1 : 'แอพพลิเคชัน',
            2 : 'ฮอตไลน์',
            3 : 'แอพพลิเคชันและฮอตไลน์',
          },
        },
        upload : {
          label : 'กรุณาแนบประวัติส่วนตัวและใบรับรองหรือหลักฐาน*',
          resume: 'อัปโหลดประวัติส่วนตัว',
          cert: 'อัปโหลดใบรับรอง',
          more: '+ อัปโหลดเพิ่ม',
          delete: 'ลบไฟล์',
        },
        btn: 'ส่งใบสมัคร',

      },
    },
  },
};
